<template>
	<div id="menus">
		<div class="col-12 novo-menu">
			<div class="row justify-end">
				<v-btn class="primary-button" raised @click="dialog_menu = true">
					<i class="mr-2 fas fa-plus"></i> Cadastrar menu
				</v-btn>
			</div>
		</div>
        <CustomTable 
			v-if="headers != ''"
			:action="'getMenus'"
			:getter="$store.getters.menus"
			:headers="headers"
			:search="true"
			:title="'menus'"
			:pagination="true"
			:filters="filtros"
			ref="tabela"
		>
			<template v-slot:acoes="{ item }">
				<v-btn class="primary-button" raised x-small @click="editarMenu(item._id)">
					Editar
				</v-btn>
			</template>
		</CustomTable>
		<v-dialog v-model="dialog_menu" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span v-if="menu.menu_id" class="headline">Editar menu</span>
					<span v-else class="headline">Nova menu</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="form_menu">
							<div class="row">
								<div class="col-12">
									<v-text-field 
										:rules="[v => !!v || 'Campo Nome obrigatório']" 
										v-model="menu.nome" 
										label="Nome" 
										placeholder="Nome" 
										outlined
									/>
								</div>
								<div class="col-12">
									<v-text-field 
										:rules="[v => !!v || 'Campo Ícone obrigatório']" 
										v-model="menu.icone" 
										label="Ícone" 
										placeholder="Ícone" 
										outlined
									/>
								</div>
								<div class="col-12">
									<v-text-field 
										:rules="[v => !!v || 'Campo Link obrigatório']" 
										v-model="menu.link" 
										label="Link" 
										placeholder="Link" 
										outlined
									/>
								</div>
							</div>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closemenu">
						Cancelar
					</v-btn>
					<v-btn class="primary-button" large @click="enviarMenu">
						Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
		<Loader v-if="loading"/>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'

	// exporta o componente
	export default {
		// nome do componente
		name: 'menus',
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um menu
			dialog_menu: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para criar/editar menu
			menu: {
				menu_id: '',
				nome: '',
				icone: '',
				link: '',
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'nome',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'icone',
					text: 'Ícone',
					sortable: true,
				},
				{
					value: 'link',
					text: 'Link',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 20,
			},
		}),
		// funções deste componente
		methods: {
			// função para enviar um menu
			async enviarMenu(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_menu.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do menu
					let dados = await {
						// coleta o nome do menu
						nome: await this.menu.nome,
						icone: await this.menu.icone,
						link: await this.menu.link,
					}
					// caso exista um menu_id 
					if(this.menu.menu_id){
						// coleta o id do menu
						dados.menu_id = await this.menu.menu_id
					}
					// faz a requisição para o back para coletar os menus
					var resp = await store.dispatch('postMenu', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Menu '  +  (this.menu.menu_id ? 'editado!' : 'cadastrado!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await 'Menu ' + (this.menu.menu_id ? 'editado' : 'cadastrado') + ' com sucesso!'
						// mostra a mensagem
						this.closemenu()
						// fecha a modal de create/edit menu
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um menu para editar
			async editarMenu(menu_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um menu passando o id 
				var resp = await store.dispatch('getMenu', menu_id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados do menu vindos do back à váriável local
					this.menu.menu_id = await resp.data._id || ''
					this.menu.nome = await resp.data.nome || ''
					this.menu.icone = await resp.data.icone || ''
					this.menu.link = await resp.data.link || ''
					// mostra a modal de creat/edit menu
					this.dialog_menu = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit menu
			closemenu(){
				// fecha a modal
				this.dialog_menu = false
				// limpa os dados locais do menu
				this.menu =  {
					menu_id: '',
					nome: '',
					icone: '',
					link: '',
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	#menus{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
	}
</style>